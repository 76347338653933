import { apiClient } from "@/services/HttpService";

// 付款审核列表
export function fetchToPayExamineList(params) {
  return apiClient.get(
    `/orders/payment-approval?orderSn=${params.orderSn}&startTime=${params.startTime}&endTime=${params.endTime}&orderType=${params.orderType}&orderCategory=${params.orderCategory}&purchaserDistributorId=${params.purchaserDistributorId}&approvalStatus=${params.approvalStatus}&pageNum=${params.pageNum}&pageSize=${params.pageSize}`
  );
}

// 快捷支付申请
export function applyQuickPay(data) {
  return apiClient.post("/pay/quickPayApply", data);
}

// 快捷支付确认
export function confirmQuickPay(data) {
  return apiClient.post("/pay/quickPayConfirm", data);
}

// 获取用户绑定的银行卡
export function getUserBankList(params) {
  return apiClient.get(`/bank-card/card-list?cardType=${params.cardType}`);
}

//线下支付 -- 查询付款记录列表
export function getPaymentList(params) {
  return apiClient.get(
    `/offline-payment?rn=${params.rn}&paymentDistributorId=${params.paymentDistributorId}&receiveDistributorId=${params.receiveDistributorId}&relTypes=${params.relTypes}&startTime=${params.startTime}&endTime=${params.endTime}&pageSize=${params.pageSize}&pageNum=${params.pageNum}&isLookCurrent=${params.isLookCurrent}`
  );
}

// 分账列表
export function fetchDivisionBillList(params) {
  return apiClient.get(
    `/share-profit?operatorDistributorId=${params.operatorDistributorId}&tocOrderSn=${params.tocOrderSn}&createStartTime=${params.createStartTime}&createEndTime=${params.createEndTime}&pageNum=${params.pageNum}&pageSize=${params.pageSize}`
  );
}

// 分账账单详情
export function fetchBillDetail(planId) {
  return apiClient.get(`/share-profit/${planId}`);
}

// 查询分账记录
export function fetchBillRecord(planId) {
  return apiClient.get(`/share-profit/pay-record/${planId}`);
}

// 查询分账收款方
export function fetchBillReceiver(planId) {
  return apiClient.get(`/share-profit/record/${planId}`);
}
