<template>
  <a-layout>
    <div class="nav">
      <Breadcrumb>
        <BreadcrumbItem>分账单明细</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <section class="card__container">
      <div class="container__title">基本信息</div>
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="8">
            <a-form-item label="分账单号">
              {{ info.planId || "-" }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="外部分账单号">
              {{ info.outShareNo || "-" }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="分账金额">
              {{ info.totalAmount === null ? "-" : info.totalAmount }}元
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="订单号">
              {{ info.tocOrderSn || "-" }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="运营商">
              {{ info.corporationName || "-" }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="分账计划时间">
              {{ info.planShareTime ? tsFormat(info.planShareTime) : "-" }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="实际分账时间">
              {{ info.finishTime ? tsFormat(info.finishTime) : "-" }}
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </section>
    <section class="card__container">
      <div class="container__title">支付记录</div>
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="8">
            <a-form-item label="支付流水号">
              {{ paymentInfo.paymentId || "-" }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="支付金额">
              {{
                paymentInfo.paymentAmount === null
                  ? "-"
                  : paymentInfo.paymentAmount
              }}元
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="支付时间">
              {{
                paymentInfo.paymentTime
                  ? tsFormat(paymentInfo.paymentTime)
                  : "-"
              }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="支付渠道">
              {{ paymentInfo.paymentChannel || "-" }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="退款总额">
              {{
                paymentInfo.refundAmount === null
                  ? "-"
                  : paymentInfo.refundAmount
              }}
              元
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="款项说明">
              <a-table
                :loading="paymentLoading"
                :data-source="paymentTypeList"
                :columns="paymentTypeColumns"
                :pagination="false"
                :row-key="
                  (record, index) => {
                    return index;
                  }
                "
              >
                <template slot="amount" slot-scope="text">
                  <span v-if="text !== null">{{ text.toFixed(2) }}</span>
                  <span v-else>--</span>
                </template>
              </a-table>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </section>
    <section class="card__container">
      <div class="container__title">分账接收方</div>
      <a-row>
        <a-col :span="16">
          <a-table
            class="table"
            :loading="loading"
            :columns="receiverColumns"
            :data-source="receiverList"
            :pagination="false"
            :row-key="
              (record, index) => {
                return index;
              }
            "
          >
            <template slot="amount" slot-scope="text">
              <span v-if="text !== null">{{ text.toFixed(2) }}</span>
              <span v-else>--</span>
            </template>
            <template slot="receivedAmount" slot-scope="text">
              <span v-if="text !== null">{{ text.toFixed(2) }}</span>
              <span v-else>--</span>
            </template>
          </a-table>
        </a-col>
      </a-row>
    </section>
  </a-layout>
</template>

<script>
import BreadcrumbItem from "@/components/BreadcrumbItem.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import {
  fetchBillDetail,
  fetchBillReceiver,
  fetchBillRecord
} from "@/services/FinancialService";
import { tsFormat } from "../../components/DateUtils";

const paymentTypeColumns = [
  {
    title: "项目",
    dataIndex: "itemName",
    width: "50%"
  },
  {
    title: "金额（元）",
    dataIndex: "amount",
    scopedSlots: { customRender: "amount" },
    width: "50%"
  }
];
const receiverColumns = [
  {
    title: "分账方",
    dataIndex: "corporationName",
    width: "20%"
  },
  {
    title: "商户编号",
    dataIndex: "outAccount",
    width: "20%"
  },
  {
    title: "分账金额（元）",
    dataIndex: "amount",
    scopedSlots: { customRender: "amount" },
    width: "20%"
  },
  {
    title: "到账金额（元）",
    dataIndex: "receivedAmount",
    scopedSlots: { customRender: "receivedAmount" },
    width: "20%"
  },
  {
    title: "分账状态",
    dataIndex: "shareProfitStatusValue",
    width: "20%"
  }
];

export default {
  components: { Breadcrumb, BreadcrumbItem },
  data() {
    return {
      loading: false,
      paymentLoading: false,
      paymentTypeList: [],
      paymentTypeColumns,
      receiverColumns,
      receiverList: [],
      planId: this.$route.params.planId,
      info: {},
      paymentInfo: {}
    };
  },
  mounted() {
    this.loadDetail();
    this.loadPaymentRecord();
    this.loadReceiver();
  },
  methods: {
    tsFormat,
    // 加载详情
    loadDetail() {
      fetchBillDetail(this.planId).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.info = resp.data.data || {};
        }
      });
    },
    // 加载支付记录
    loadPaymentRecord() {
      this.paymentLoading = true;
      fetchBillRecord(this.planId)
        .then(resp => {
          this.paymentLoading = false;
          if (resp.data.code === "SUCCESS") {
            this.paymentInfo = resp.data.data || {};
            this.paymentTypeList =
              this.paymentInfo && this.paymentInfo.paymentDescriptions
                ? this.paymentInfo.paymentDescriptions
                : [];
          }
        })
        .catch(() => {
          this.paymentLoading = false;
        });
    },
    // 加载收款方
    loadReceiver() {
      this.loading = true;
      fetchBillReceiver(this.planId)
        .then(resp => {
          this.loading = false;
          if (resp.data.code === "SUCCESS") {
            this.receiverList = resp.data.data || [];
          }
        })
        .catch(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped>
.container__title {
  color: rgba(16, 16, 16, 1);
  font-size: 14px;
  font-weight: bold;
}

/deep/ .ant-form-item {
  margin: 10px 40px 0 0;
}

/deep/ .ant-form-item .ant-form-item-label {
  min-width: 98.5px !important;
  text-align: left !important;
}

/deep/ .ant-form-item-label > label {
  color: rgba(102, 102, 102, 0.8);
  font-size: 14px;
}

.table {
  margin-top: 20px;
}
</style>
